import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivitiesService } from '../services/activities.service';
import { EventsService } from '../services/events.service';
import { TasksService } from '../services/tasks.service';
import { UserinfoService } from '../services/userinfo.service';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy { 

  isHandset$: Observable<boolean> = this._breakpointObserver.observe('(max-width: 800px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  private subscription: Subscription;
  private subscription2: Subscription;
  private subscriptionScroll: Subscription;

  loading: boolean = true;
  loadingCalendar: boolean = true;
  loadingTasks: boolean = true;
  loadingData: boolean = false;

  activities: any[] = [];

  events: any[] = [];
  tasks: any[] = [];

  user: any;
  lastOffset: number;

  // calendar
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events2: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;
  
  constructor(
    private _activitiesService: ActivitiesService,
    private _userinfoService: UserinfoService,
    private _snackBar: MatSnackBar,
    private _eventService: EventsService,
    private _tasksService: TasksService,
    public _scroll: ScrollDispatcher,
    private _zone: NgZone,
    private _titleService: Title,
    private _breakpointObserver: BreakpointObserver,
  ) { 
    //let firstTime = true;

    this.subscription = this._userinfoService.loggedIn$.subscribe((value) => {
      //if(!firstTime) {
        if(value) {
          this.user = this._userinfoService.getLocalInfo();
        }
      /*}
      else {
        firstTime = false;
      }*/
    });

    this.subscriptionScroll = this._scroll.scrolled().subscribe((data: CdkScrollable) => {

      this._zone.run(() => {
        this.onWindowScroll(data);
      });

    });
    
  }

  private onWindowScroll(data: CdkScrollable) {
    const scrollTop = data.getElementRef().nativeElement.scrollTop || 0;
    if (this.lastOffset > scrollTop) {
      // console.log('Show toolbar');
    } else if (scrollTop < 10) {
      // console.log('Show toolbar');
    } else if (scrollTop > 100) {
      // console.log('Hide toolbar');
    }

    if((data.getElementRef().nativeElement.scrollHeight - scrollTop) < 1300) {
      
      if(!this.loadingData) {
        this.loadingData = true;


        if(this.activities[this.activities.length - 1]) {
      
          this._activitiesService.getActivites(this.activities[this.activities.length - 1].date_created).subscribe((result: any) => {

            if(result.success) {
      
              //@todo
              if(result.data.length > 0) {

                for(let d of result.data) {

                  this.activities.push(d);

                }

              }
      
            }
      
            this.loading = false;
      
          }, err => {
      
            this.loading = false;
      
            if (err.status != 200) {
      
              // snackbar
              this._snackBar.open('Error', '', {
                duration: 2000,
                panelClass: ['error-snackbar']
              });
      
            }
          });


        }
        
      }

    }
    else {
      this.loadingData = false;
    }


    this.lastOffset = scrollTop;
  }

  ngOnInit(): void {   

    this._titleService.setTitle('Home | ExArca');

    this._activitiesService.getActivites().subscribe((result: any) => {

      if(result.success) {

        this.activities = [...result.data];//result.data;        

      }

      this.loading = false;

    }, err => {

      this.loading = false;

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

    this._eventService.getUserEventsThisMonth().subscribe((result: any) => {

      if(result.success) {       

        this.events = [...result.data];

      }
      

    }, err => {

      if (err.status != 200) {

        

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });


    this._tasksService.getUserTasks().subscribe((result: any) => {

      if(result.success) {

        this.loadingTasks = false;

        this.tasks = result.data;      

      }
      else {
        this.loadingTasks = false;
      }

    }, err => {

      if (err.status != 200) {

        this.loadingTasks = false;

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

  }

  changeStatus(task: any) {
    if(task.level_id) {
      this._tasksService.changeStatus(task.level_id._id, task._id, task.userStatus).subscribe((result: any) => {

        if (result.success) {        
          
          
          task.status = result.data.status;


        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {  
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      }); 
    }
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }

    if(this.subscription2) {
      this.subscription2.unsubscribe();
    }

    if(this.subscriptionScroll) {
      this.subscriptionScroll.unsubscribe();
    }
  }

}
