<div class="mb-2 mb-xl-6">

    <div class="row">
        <div class="col-xl-7 col-12">

            <div class="row" *ngIf="_user && _user.credits && _user.credits > 0">
                <div class="col">
                    <div class="mb-9">

                        <app-create-platform-user [credits]="_user.credits"></app-create-platform-user>

                    </div>

                </div>
            </div>

            <div class="d-flex mb-lg-4 text-center text-md-start justify-content-center justify-content-md-start">

                <div class="text-primary me-4 d-none d-md-block">
                    <mat-icon>auto_awesome</mat-icon>
                </div>

                <div class="display-5">Hello, welcome back! <div class="text-muted h4 mt-2 mt-md-0">Here are the new
                        things that
                        happened ...</div>
                </div>

            </div>
            

            <ng-container *ngIf="_activities.length == 0; else activities">

                <div class="bg-white rounded p-2 px-5">
                    <div class="d-flex flex-column align-items-center justify-content-center w-100">                        
                        <!--<img src="assets/no-data.png" style="width: 200px;" />-->
                        <div class="text-muted my-2 h4">{{'No activities.' | translate}}</div>                        
                    </div>
                </div>

                <app-start-collaborating></app-start-collaborating>

                

            </ng-container>

            <ng-template #activities>

                <div class="d-none d-lg-block"
                    style="background: #dee2e6; border-radius: 50%; width: 15px; height: 15px; position: absolute; margin-left: 18px;">
                </div>

                <div class="py-2 py-md-4 activity-line">

                    <ng-container *ngFor="let a of _activities">

                        <ng-container *ngIf="a.activity_type == 'newTask'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>leaderboard</mat-icon>
                                </div>


                                <div style="width: 20px; min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>



                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new task
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="py-4">

                                            <div class="d-flex align-items-center p-4 rounded"
                                                style="border: 1px dashed #dee2e6 !important">

                                                <div class="flex-fill">
                                                    <div class="fw-boldest mb-2">
                                                        {{ a.options.task_name }}
                                                    </div>

                                                    <div class="text-muted">Due: {{ a.options.task_due | date:
                                                        "longDate" }}</div>
                                                </div>

                                                <!--
                                                        <div class="flex-fill d-flex">
                                                            
                                                            <div class="text-center" style="width: 25px; height: 25px; background: red; color: #fff; border-radius: 50%; border: 2px solid #fff; font-size: 12px;">LS</div>

                                                            <div class="text-center" style="width: 25px; height: 25px; background: purple; color: #fff; border-radius: 50%; border: 2px solid #fff; font-size: 12px; margin-left: -10px;">LS</div>

                                                        </div>-->


                                            </div>

                                        </div>



                                    </div>


                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>


                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newMilestone'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>track_changes</mat-icon>
                                </div>


                                <div style="width: 20px; min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>

                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>

                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new milestone
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="d-flex text-center align-items-center justify-content-center p-5"
                                            style="background: url(assets/activity-bg.svg); background-position: top center;">

                                            <div class="text-primary">
                                                <mat-icon>track_changes</mat-icon>
                                            </div>

                                            <div class="ms-2">
                                                <h2 class="mb-0">{{ a.options.milestone_name }}</h2>

                                                {{ a.options.milestone_due | date: "longDate" }}
                                            </div>


                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>



                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>


                        <ng-container *ngIf="a.activity_type == 'newEvent'">


                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>event</mat-icon>
                                </div>


                                <div style="width: 20px; min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">


                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new event
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="d-flex flex-column text-center justify-content-center p-5"
                                            style="background: url(assets/activity-bg2.svg); background-position: top center;">

                                            <div class="text-primary">
                                                <mat-icon>event</mat-icon>
                                            </div>

                                            <h2 class="mb-0">{{ a.options.event_name }}</h2>

                                            {{ a.options.event_start_date | date: "longDate" }} - {{
                                            a.options.event_end_date | date: "longDate" }}


                                        </div>

                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>



                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>


                        <ng-container *ngIf="a.activity_type == 'newComment'">

                            <!-- ACTIVITY -->
                            <div class="d-lg-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>format_quote</mat-icon>
                                </div>


                                <div style="min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new comment in
                                                            </div>
                                                            <div class="fw-bold text-primary"
                                                                [matTooltip]="a.options.topic_name">{{
                                                                a.options.topic_name | truncate:45 }}</div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="py-2" style="overflow-wrap: break-word;"
                                            [innerHTML]="a.options.conversation_text | sanitizeHtml">
                                        </div>

                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>



                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>


                        <ng-container *ngIf="a.activity_type == 'newTopic'">

                            <!-- ACTIVITY -->
                            <div class="d-lg-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>topic</mat-icon>
                                </div>


                                <div style="width: 20px; min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new topic
                                                            </div>
                                                            <div class="fw-bold text-primary"
                                                                [matTooltip]="a.options.topic_name">{{
                                                                a.options.topic_name | truncate:45 }}</div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <!--
                                            <div class="p-3">

                                                {{ a.options.conversation_text | mystriphtml | truncate:160 }}

                                            </div>-->
                                        <div class="py-2" style="overflow-wrap: break-word;"
                                            [innerHTML]="a.options.conversation_text | sanitizeHtml">
                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                            <button mat-button color="secondary" class="ms-2">
                                                <mat-icon inline="true">
                                                    favorite
                                                </mat-icon>
                                                Like
                                            </button>-->
                                    </div>

                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newFile'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>upload_file</mat-icon>
                                </div>


                                <div style="width: 20px; min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new file
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>


                                        <div class="py-4 px-2 px-md-4">

                                            <div class="d-flex align-items-center p-2">
                                                <div class="me-2">
                                                    <mat-icon class="text-muted">upload_file</mat-icon>
                                                </div>
                                                <div>

                                                    <div class="mb-2 fw-bold" style="word-break: break-all;">
                                                        {{ a.options.file_name }}
                                                    </div>

                                                    <span class="text-primary fw-bold text-uppercase pointer"
                                                        [routerLink]="a.link"> {{ 'View' | translate }}</span>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>



                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newNews'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>article</mat-icon>
                                </div>


                                <div style="min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new news
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="p-3">

                                            <h2 class="mat-h2">{{ a.options.news_name }}</h2>

                                            {{ a.options.news_description | mystriphtml | truncate:160 }}
                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>


                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newBlog'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>rss_feed</mat-icon>
                                </div>


                                <div style="min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new blog
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="p-3">

                                            <h2 class="mat-h2">{{ a.options.blog_name }}</h2>

                                            {{ a.options.blog_description | mystriphtml | truncate:160 }}
                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>


                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newWiki'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>assignment</mat-icon>
                                </div>


                                <div style="min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new wiki
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div class="p-3">

                                            <h2 class="mat-h2">{{ a.options.wiki_name }}</h2>
                                            
                                            <ng-container *ngIf="a.options.wiki_content">
                                                {{ a.options.wiki_content | mystriphtml | truncate:160 }}
                                            </ng-container>
                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>


                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newLevel'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>add</mat-icon>
                                </div>


                                <div style="min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new level
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <!--
                                        <div class="p-3">

                                            <h1>{{ a.level_id?.name }}</h1>
                                            
                                        </div>-->

                                        <div class="d-flex flex-column text-center justify-content-center p-5" [ngClass]="a.level_id?.type"
                                            style="background: url(assets/activity-bg3.svg); background-position: top center;">

                                            <div>
                                                <mat-icon>layers</mat-icon>
                                            </div>

                                            <h2 class="mb-0">{{ a.level_id?.name }}</h2>

                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>

                                        <!--
                                                <button mat-button color="secondary" class="ms-2">
                                                    <mat-icon inline="true">
                                                        favorite
                                                    </mat-icon>
                                                    Like
                                                </button>
                                                -->
                                    </div>


                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                        <ng-container *ngIf="a.activity_type == 'newProjectProposal'">

                            <!-- ACTIVITY -->
                            <div class="d-flex align-items-center">

                                <div class="d-lg-flex align-items-center justify-content-center d-none activity-icon">
                                    <mat-icon>note_add</mat-icon>
                                </div>


                                <div style="min-width: 20px;" class="d-none d-lg-block w-20px">

                                </div>


                                <mat-card class="my-3 my-md-5 flex-fill">

                                    <div class="activity">

                                        <app-activity-level-info [levelId]="a.level_id"></app-activity-level-info>


                                        <div class="author my-2">

                                            <div class="d-flex align-items-center">

                                                <app-user-avatar [info]="a.user_id" width="40"></app-user-avatar>

                                                <div class="ms-3">
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap mb-1">
                                                            <div class="fw-bold text-primary me-2">{{
                                                                a.user_id.full_name
                                                                }}</div>
                                                            <div class="fw-bold me-2">
                                                                added new project proposal
                                                            </div>
                                                        </div>
                                                        <div class="text-muted">{{ a.date_created | myDate }}</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>


                                        <div class="d-flex flex-column text-center justify-content-center p-5" [ngClass]="a.level_id?.type"
                                            style="background: url(assets/activity-bg4.svg); background-position: top center;">

                                            <div>
                                                <mat-icon>note_add</mat-icon>
                                            </div>

                                            <h2 class="mb-0">{{ a.options?.project_proposal_title }} 
                                                <div class="text-muted" style="font-size: .9rem">{{ a.options?.project_proposal_nickname }} </div>
                                            </h2>

                                        </div>


                                    </div>

                                    <div class="card-footer">
                                        <button mat-button color="secondary" [routerLink]="a.link">
                                            {{ 'View' | translate }}
                                        </button>                                       
                                    </div>


                                </mat-card>

                            </div>
                            <!-- /ACTIVITY -->

                        </ng-container>

                    </ng-container>

                </div>

                <div class="d-none d-lg-block"
                    style="background: #dee2e6; border-radius: 50%; width: 15px; height: 15px; position: absolute; margin-left: 18px;">
                </div>

                <div class="d-none d-lg-block" style="height: 40px;"></div>

            </ng-template>


        </div>

        <div class="col col-xl-5">
            <app-my-tasks-view [tasks]="_tasks"></app-my-tasks-view>

            <app-my-event-view [events]="_events"></app-my-event-view>

            <!-- @todo faq section -->
        </div>

    </div>

</div>