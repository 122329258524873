import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-activity-b',
  templateUrl: './activity-b.component.html',
  styleUrls: ['./activity-b.component.scss']
})
export class ActivityBComponent implements OnInit {

  _user: any;
  _activities: any = [];
  _tasks: any = [];
  _events: any = [];

  @Input() set user(user: any) { this._user = user }
  @Input() set activities(activities: any) { this._activities = [...activities] }
  @Input() set tasks(tasks: any) { this._tasks = [...tasks] }
  @Input() set events(event: any) { this._events = [...event] }

  constructor(
  ) { }

  ngOnInit(): void {    

  }

}
