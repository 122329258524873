<div class="container mt-4 mt-lg-8">
    <div class="row">
        <div class="col">

            <ng-container *ngIf="loading">

                <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </ng-container>
            <ng-container *ngIf="!loading">

                <!--
                    A/B Testing for activites
                -->

                <!--
                <ng-container *ngIf="!user.experiment_test; else experiment">

                    <app-activity-a  [user]="user" [activities]="activities" [tasks]="tasks" [events]="events"></app-activity-a>

                </ng-container>

                <ng-template #experiment>

                    <app-activity-b [user]="user" [activities]="activities" [tasks]="tasks" [events]="events"></app-activity-b>

                </ng-template>-->

                <app-activity-b [user]="user" [activities]="activities" [tasks]="tasks" [events]="events"></app-activity-b>

                
            </ng-container>

        </div>
    </div>

</div>